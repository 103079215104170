.market{
    background-color: #071820;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h2{
    color: whitesmoke;
    font-size: 40px;
    padding: 20px;
}

.market span{
    color:goldenrod;
    margin: 0;
    padding: 0;
}

.coins{
    width: 90%;
    align-items: center;
}