*{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
    color: whitesmoke;
}

p{
    display: inline-block;
}

.coin{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background-color: #06141a;
    margin-bottom: 5px;
    border-radius: 15px;
    transition: all 300ms ease;
}

.coin:hover{
    transform: translateX(10px);
}

.coin div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.coin .name-logo{
    justify-content: flex-start;
    gap: 10px;
}

.up{
    color: green;
}

.down{
    color: red;
}

.up p span{
    margin-right: 10px;
}

.down p span{
    margin-right: 10px;
}