.chart {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #06141a;
}

.line {
  width: 80%;
}

.loading {
  height: 50vh;
  align-items: center;
  justify-content: center;
  background-color: #071820;
  color: goldenrod;
  padding: 20px;
  font-size: 20px;
}
